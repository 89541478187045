import { useEffect } from "react";
import { GameWonType } from "../types/GameWonType";
import style from "./GameWon.module.css";

const GameWon = ({ usedClues, time }: GameWonType) => {
  const zeroPad = (num: number) => String(num).padStart(2, "0");

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <div className={style.container}>
      <h1>Game Won!</h1>
      <h2>
        Remaining time {zeroPad(time.getMinutes())}:{zeroPad(time.getSeconds())}
      </h2>
      <h3>Used clues {usedClues}</h3>
    </div>
  );
};

export default GameWon;
