import { useCallback, useState } from "react";
import style from "./Login.module.css";
import { LoginType } from "../types/LoginType";
import { XCircle, CheckCircle, SignIn } from "phosphor-react";
import { ValidationType } from "../types/ValidationType";
import { colorPalette } from "../util/colorPalette";
import Shapes from "../shapes/Shapes";
import { validateName, validatePassword } from "../api/api";

const Login = ({ onSuccessfulLogin }: LoginType) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [controlNum, setControlNum] = useState<string>("");
  const [shapes, setShapes] = useState<JSX.Element[]>([]);
  const [validation, setValidation] = useState<ValidationType>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [rewriteShapes, setReWriteShapes] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await validateCred();
    setIsSubmitted(true);
  };

  const validateCred = async () => {
    let isValid = {
      ...validation,
      username: await validateName(username),
      password: await validatePassword(password),
      controlNum: controlNum === calculateControlNumber().toString(),
    };
    setValidation(isValid);

    if (isValid.username && isValid.password && isValid.controlNum) {
      onSuccessfulLogin(true);
      return;
    }
    setReWriteShapes((prev) => !prev);
  };

  const calculateControlNumber = (): number => {
    const colors = colorPalette();
    const red = colors.filter((c) => c.color.name === "red")[0].color.hex;
    const blue = colors.filter((c) => c.color.name === "blue")[0].color.hex;
    const green = colors.filter((c) => c.color.name === "green")[0].color.hex;
    const yellow = colors.filter((c) => c.color.name === "yellow")[0].color.hex;

    let count: number = 0;
    shapes.forEach((shape) => {
      if (shape.props.style.color === red) {
        count += 1;
      } else if (shape.props.style.color === green) {
        if (shape.key === "circle") {
          count += 0;
        } else if (shape.key === "square") {
          count += 4;
        } else if (shape.key === "triangle") {
          count += 3;
        }
      } else if (shape.key === "circle") {
        count *= 2;
      } else if (shape.props.style.color === blue) {
        count -= 3;
      } else if (shape.props.style.color === yellow && shape.key === "square") {
        count *= 4;
      } else count -= 5;
    });
    return count;
  };

  const updateShapes = useCallback((shapes: JSX.Element[]) => {
    setShapes(shapes);
  }, []);

  return (
    <div className={style.container}>
      <form onSubmit={handleSubmit}>
        <div>
          <div className={style.row}>
            {isSubmitted && validation.username && (
              <CheckCircle className={style.iconCheck} />
            )}
            {isSubmitted && !validation.username && (
              <XCircle className={style.iconCross} />
            )}

            <input
              className={style.input}
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              id="username"
              autoComplete="off"
            />

            <label
              className={username.length > 0 ? style.valid : ""}
              htmlFor="username"
            >
              <span>Användarnamn</span>
              <span>Förnamn</span>
            </label>
          </div>
        </div>

        <div>
          <div className={style.row}>
            {isSubmitted && validation.password && (
              <CheckCircle className={style.iconCheck} />
            )}
            {isSubmitted && !validation.password && (
              <XCircle className={style.iconCross} />
            )}
            <input
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              autoComplete="off"
            />
            <label
              className={password.length > 0 ? style.valid : ""}
              htmlFor="password"
            >
              <span>Lösenord</span>
            </label>
          </div>
        </div>

        <div>
          <div className={style.row}>
            {isSubmitted && validation.controlNum && (
              <CheckCircle className={style.iconCheck} />
            )}
            {isSubmitted && !validation.controlNum && (
              <XCircle className={style.iconCross} />
            )}
            <input
              onChange={(e) => setControlNum(e.target.value)}
              id="controlNumber"
              autoComplete="off"
            />
            <label
              className={controlNum.length > 0 ? style.valid : ""}
              htmlFor="controlNumber"
            >
              <span>Kontrollsiffra</span>
              <Shapes
                onShapes={(shapes: JSX.Element[]) => updateShapes(shapes)}
                reWriteShapes={rewriteShapes}
              />
            </label>
          </div>
        </div>

        <div className={style.inputWrapper}>
          <SignIn className={style.btnIcon} />
          <button type="submit">
            <span>Logga in</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
