import { useRef, useState } from "react";
import { CluesType } from "../types/CluesType";
import Card from "./Card";
import style from "./Clues.module.css";
const Clues = ({ onUsedClues }: CluesType) => {
  const [usedClues, setUsedClues] = useState<number>(0);

  const clueCounter = useRef(() => {
    if (usedClues >= 5) return;
    setUsedClues((prev) => prev + 1);
    onUsedClues();
  });

  return (
    <div className={style.container}>
      <h1 className={style.header}>
        <span className={style.clueHeader}>Använda ledtrådar: </span>
        <span className={style.clues}>{usedClues} </span>
      </h1>
      <div className={style.cardsContainer}>
        <Card
          clueCounter={clueCounter.current}
          name={"Användarnamn"}
          msg={`Vem sitter på platsen som har möjlighet att logga in?

          Dvs vem är det som sitter vid tangentbordet?`}
        />
        <Card
          clueCounter={clueCounter.current}
          name={"Bordsplacering"}
          msg={`Finns det någon plats där bara en av personerna kan sitta? Börja där.

          Kan man lista ut något genom uteslutningsmetoden?

          Tips: Börja med Joan och Herman, därefter Katherine, Charles, osv`}
        />
        <Card
          clueCounter={clueCounter.current}
          name={"Kontrollsiffra"}
          msg={`Inuti användarens porträtt finns en pseudokod. 

          Med hjälp av koden och de 3 symbolerna vid kontrollsiffran får ni ut ett värde för kontrollsiffran. 

          Svårt att förstå koden? Fråga oss så förklarar vi.`}
        />
        <Card
          clueCounter={clueCounter.current}
          name={"Lösenord"}
          msg={`På skärmen bredvid kan ni se att fönstret heter “password in red”. De röda siffrorna som faller ner är binärkod, och tillsammans med ascii-konverteraren som ligger i boken på bordet kan ni nu hitta lösenordet. Binärkoden är de åtta siffrorna från stjärna till stjärna, uppifrån och ner   00011011 01000001 01010000 01000101`}
        />
        <Card
          clueCounter={clueCounter.current}
          name={"Tangentbord"}
          msg={`För att skriva in användarnamn, lösenord och kontrollsiffra behöver man ha tillgång till tangentbordet. 

          Batterierna är dock utplockade, så ni behöver leta dessa i rummet. De är varken fisk eller fågel.`}
        />
      </div>
    </div>
  );
};

export default Clues;
