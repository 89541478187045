import Login from "../login/Login";
import Timer from "../timer/Timer";
import style from "./Game.module.css";
import { GameType } from "../types/GameType";
import Clues from "../clues/Clues";

const Game = ({
  onUsedClues,
  onResultTime,
  onTimeIsUp,
  onSuccessfulLogin,
}: GameType) => {
  const TWENTY_MIN_IN_MS: number =
    sessionStorage.getItem("time") !== null
      ? Number(sessionStorage.getItem("time"))
      : 1200000;

  return (
    <div className={style.wrapper}>
      <div className={style.gameContainer}>
        <Timer
          initialTimeInMs={TWENTY_MIN_IN_MS}
          onTimeIsUp={onTimeIsUp}
          onResultTime={onResultTime}
        />
        <Login onSuccessfulLogin={onSuccessfulLogin} />
        <Clues onUsedClues={onUsedClues} />
      </div>
    </div>
  );
};

export default Game;
