import style from "./Info.module.css";
const Info = () => {
  return (
    <div className={style.textBox}>
      <p>
        Om <span>20 minuter</span> kommer ett gäng kända tech-personer inta
        rummet för att tillsammans ha ett topphemligt möte. Vad mötet handlar om
        har ni ingen aning om, men nyfikna som ni är vill ni ta reda på det. Ni
        ser att det finns en dator uppkopplad i rummet, så det enda ni behöver
        göra för att komma åt innehållet är att{" "}
        <span> slå era kloka huvuden ihop och logga in på datorn.</span>
      </p>
    </div>
  );
};

export default Info;
