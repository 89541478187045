export const colorPalette = () => {
    const colors = [
      {
        color: {
          name: "red",
          hex: "#f15d58",
        },
      },
      {
        color: {
          name: "green",
          hex: "#17825e",
        },
      },
      {
        color: {
          name: "blue",
          hex: "#00a0e9",
        },
      },
      {
        color: {
          name: "yellow",
          hex: "#e1f05d",
        },
      },
    ];
    return colors;
  };