import style from "./Timer.module.css";
import { TimerType } from "../types/TimerType";
import { useCallback, useEffect, useRef, useState } from "react";

const Timer = ({ initialTimeInMs, onTimeIsUp, onResultTime }: TimerType) => {
  const timeInMs = useRef(initialTimeInMs);
  const [time, setTime] = useState<Date>(new Date(initialTimeInMs));

  const handleTime = useCallback(() => {
    if (timeInMs.current <= 0) {
      sessionStorage.clear();

      onTimeIsUp();
      return;
    }
    sessionStorage.setItem("time", timeInMs.current.toString());
    onResultTime(timeInMs.current);
    setTime(new Date(timeInMs.current));
  }, [timeInMs, onTimeIsUp, onResultTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      timeInMs.current = timeInMs.current - 1000;
      handleTime();
    }, 1000);
    return () => clearInterval(interval);
  }, [handleTime]);
  const zeroPad = (num: number) => String(num).padStart(2, "0");
  return (
    <div className={style.container}>
      <div className={style.timerContainer}>
        <h1 className={style.timerHeader}>
          {zeroPad(time.getMinutes())}:{zeroPad(time.getSeconds())}
        </h1>
      </div>
    </div>
  );
};

export default Timer;
