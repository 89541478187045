const API_URL = "https://escape-room-api.azurewebsites.net"; // https://localhost:7216";

export const validateName = async (username: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/user/${username}`);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const validatePassword = async (password: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/password/${password}`);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};
