import { useEffect } from "react";
import { GameOverType } from "../types/GameOverType";
import style from "./GameOver.module.css";
const GameOver = ({ usedClues }: GameOverType) => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  return (
    <div className={style.container}>
      <h1 className={style.green}>Game Over!</h1>
      <h2 className={style.white}>You have reached the end of the game.</h2>
      <h3 className={style.white}>
        Used clues <span>{usedClues}</span>
      </h3>
    </div>
  );
};

export default GameOver;
