import style from "./Intro.module.css";
import { IntroType } from "../types/IntroType";
import Info from "../info/Info";

const Intro = ({ onStartGame }: IntroType) => {
  return (
    <div className={style.container}>
      <h1 className={style.header}>Escape room</h1>
      <Info />
      <div className={style.line}></div>
      <button className={style.button} onClick={onStartGame}>
        Start
      </button>
    </div>
  );
};

export default Intro;
